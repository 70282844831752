import { Box, Collapse, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { FC, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useAtom } from 'jotai';
import { endOfDay, startOfDay } from 'date-fns';

import { ID } from 'domain/types/ID';
import { useCaseGetServiceTypes } from 'application/services/useCases/useCaseGetServiceTypes';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { atomWithToggleAndStorage } from 'infrastructure/utils/atomWithToggleAndStorage';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { componentShadows } from 'targets/web/theme/shadows';
import { Button, DatePicker, Select, SimpleSelectItem, TextField } from 'targets/web/components';
import { FilterCustomerAutocomplete } from 'targets/web/modules/dashboard/components/FilterCustomerAutocomplete/FilterCustomerAutocomplete';
import { localTimeToUTC, utcToLocalTime } from 'targets/web/modules/jobs/utils';

import { AircraftAutocomplete } from './AircraftAutocomplete';
import { StationAutocomplete } from './StationAutocomplete';

export interface Filters {
  phrase: string;
  customerId: ID;
  stationIds: ID[];
  aircraftId: ID;
  serviceTypeId: ID;
  dateStart: string;
  dateEnd: string;
}

interface FiltersContainerProps {
  initialValues?: Pick<
    Partial<Filters>,
    | 'phrase'
    | 'dateStart'
    | 'dateEnd'
    | 'stationIds'
    | 'customerId'
    | 'aircraftId'
    | 'serviceTypeId'
  >;
  onFiltersChange: (values: Partial<Filters>) => void;
}

const filtersCollapsedAtom = atomWithToggleAndStorage('isFilterContainerCollapsed', false);

export const FiltersContainer: FC<FiltersContainerProps> = ({ initialValues, onFiltersChange }) => {
  const t = useTranslationPrefix('components.filters_container');

  const { serviceTypes } = useCaseGetServiceTypes();

  const services: SimpleSelectItem[] = useMemo(
    () =>
      [
        {
          value: 'all',
          label: 'All',
        },
      ].concat(serviceTypes?.map(({ name, id }) => ({ label: name, value: id })) ?? []),
    [serviceTypes],
  );

  const [filtersCollapsed, toggleFilters] = useAtom(filtersCollapsedAtom);

  const onClickHandler = useCallback(() => toggleFilters(), [toggleFilters]);

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((e) => {
        const phrase = e.target.value.trim();
        onFiltersChange({ phrase: phrase.length > 2 ? phrase : undefined });
      }, DEFAULT_DEBOUNCE_TIME),
    [onFiltersChange],
  );

  return (
    <Stack
      width={1}
      data-testname="filtersContainer"
      sx={{
        padding: 6,
        borderRadius: 1,
        backgroundColor: 'background.default',
        boxShadow: componentShadows.card,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">{t('header')}</Typography>
        <Button
          size="small"
          variant="text"
          endIcon={filtersCollapsed ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          onClick={onClickHandler}
        >
          {filtersCollapsed ? t('hide_filters') : t('show_filters')}
        </Button>
      </Stack>

      <Collapse in={filtersCollapsed}>
        <Box mt={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                defaultValue={initialValues?.phrase}
                onChange={debouncedSearchHandler}
                placeholder={t('search_label')}
                data-testname="searchInput"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <DatePicker
                defaultValue={
                  initialValues?.dateStart
                    ? utcToLocalTime(new Date(initialValues.dateStart))
                    : undefined
                }
                label={t('date_from_label')}
                format="MMM dd yyyy"
                onChange={(value) =>
                  onFiltersChange({
                    dateStart: value ? localTimeToUTC(startOfDay(value)).toISOString() : undefined,
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                defaultValue={
                  initialValues?.dateEnd
                    ? utcToLocalTime(new Date(initialValues.dateEnd))
                    : undefined
                }
                label={t('date_to_label')}
                format="MMM dd yyyy"
                onChange={(value) => {
                  onFiltersChange({
                    dateEnd: value ? localTimeToUTC(endOfDay(value)).toISOString() : undefined,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <StationAutocomplete
                initialValueIds={initialValues?.stationIds}
                onChange={(stations) => {
                  onFiltersChange({ stationIds: stations.map((station) => station.id) });
                }}
              />
            </Grid>

            <Grid item xs>
              <FilterCustomerAutocomplete
                label={t('customer_label')}
                initialValueId={initialValues?.customerId}
                onChange={(customerId) => {
                  onFiltersChange({ customerId });
                }}
              />
            </Grid>
            <Grid item xs>
              <AircraftAutocomplete
                initialValueId={initialValues?.aircraftId}
                onChange={(aircraftId) => {
                  onFiltersChange({ aircraftId });
                }}
              />
            </Grid>
            <Grid item xs>
              <Select
                label={t('service_label')}
                items={services}
                defaultValue={initialValues?.serviceTypeId || 'all'}
                data-testname="serviceFilter"
                onChange={({ target }) =>
                  onFiltersChange({
                    serviceTypeId: target.value !== 'all' ? ID(target.value) : undefined,
                  })
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Stack>
  );
};
